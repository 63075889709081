.steampunk-button {
  padding: 0.5rem 1rem;
  background-color: rgb(197, 197, 197);
  border: solid 1px black;
  border-radius: 0.25rem;
  color: #fff;
  font-size: 0.875rem;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.steampunk-button:hover {
  background-color: #946428;
}

.button-text {
  color: #3182ce;
}

body h1,
#to-top {
  font-family: 'Agdasima', sans-serif;
}